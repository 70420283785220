* { 
    padding: 0; 
    margin: 0;
    box-sizing: border-box !important;
}

html, body, #fullheight {
    min-width: 360px;
    max-width: 1920px;
    height: 100%;
    margin: 0 auto !important;
}

Footer {
    clear: both;
    position: relative;
    height: 200px;
    margin-top: -200px;
}

App {
 min-height: 80vh;
}

body {
    font-family: 'Helvetica Neue', Arial, sans-serif;
}

.estilo1 {
    font-size: 25px;
    margin: 2px 0;
    color: #555;
    text-align: center;
    text-decoration: none;
}

.estilo2 {
    font-size: 20px;
    color: #444;
    margin: 5px 0;
    text-align: center;
}

.estilo2 {
    font-size: 18px;
    margin: 2px 0;
    color: #555;
    text-align: center;
    text-decoration: none;
}

.estilo3 {
    font-size: 12px;
    margin: 2px 0;
    color: #555;
    text-align: center;
    text-decoration: none;
}

.estilo4 {
    font-size: 10px;
    margin: 2px 0;
    color: #555;
    text-align: center;
    text-decoration: none;
}