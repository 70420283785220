.contato-container {
    min-height: 77vh;
    font-family: 'Helvetica Neue', Arial, sans-serif;
}

.contato-list{
    width: 80%;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
}

.contato-item{
    width: 33%;
    min-width: 250px;
    margin-top: 2%;
    margin-bottom: 2%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    font-family: 'Helvetica Neue', Arial, sans-serif;
}

.contato-item a {
    font-size: 12px;
    margin: 2px 0;
    color: #555;
    text-align: center;
    text-decoration: none;
}

.contato-map{
    margin: 0 auto;
    width: 80%;
}


.contato-map-link{
    text-align: center;
    margin: 25px 0;
    cursor: pointer;
}

div a {
    text-decoration-color: black;
    text-decoration: none;
}

h5 {
    font-size: 18px;
    margin: 2px 0;
    color: #555;
    text-align: center;
    /* Centralize o texto */
}

.frame {
    width: 100%;
}

.estilo-rota {
    font-size: 25px;
    margin: 2px 0;
    color: #555;
    text-align: center !important;
    text-decoration: none;
}

/* Estilos para h4 em telas de celular */
@media (max-width: 767px) {
    .contato-map-link {
        text-align: center;
        margin: 15px 0;
    }
    .estilo-rota{
        width: 80%;
        font-size: 15px;
        color: #fff;
        margin: 0 auto;
        margin-top: 2cqb;
        text-align: center;
        border: 1px #666 solid;
        border-radius: 10px;
        padding: 10px;
        background-color: #085625;
    }
        .contato-list {
            width: 100%;
            margin: 0 auto;
            text-align: center;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: flex-start;
        }
}