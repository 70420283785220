.sobre-container{
    background-color: rgb(255, 255, 255);
    min-height: 80vh;
}

.sobre-item {
    max-width: 80%;
    margin: 0 auto;
    text-align: center;
}

.estilo1 {
    margin: 20px 0 !important;
}

ul {
    list-style: circle;
    padding-left: 20px;
}

li {
    text-align: justify;
    /* Justificar o texto da lista */
    margin: 5px 0;
}

li strong {
    font-weight: bold;
    /* Defina o texto dentro do <strong> em negrito */
}


/* Estilos para h4 em telas de desktop */
@media (min-width: 768px) {
   
}

/* Estilos para h4 em telas de celular */
@media (max-width: 767px) {
    p {
        text-align: justify !important;
    }
}