/* styles.css */

.usuario-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-items: center;
    /* Alinhar ao topo */
    min-height: 80vh;
    /* 100% da altura da viewport */
}

.info-retangulo {
    margin: 15px;
    
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

.titulo {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.info-row {
    display: flex;
    flex-direction: row;
    justify-content: start;
    gap: 35px;
    flex-wrap: wrap;

}

.info-retangulo div {
    margin-bottom: 10px;
}

/* Estilos para dispositivos móveis (max-width: 767px) */
@media (max-width: 767px) {
    .usuario-container {
        align-items: center;
        align-items: flex-start;
        margin: 15px;
        /* Centralizar na tela */
    }

    .info-retangulo {
        width: 100%;
    }

    .titulo {
        font-size: 16px;
    }
        .info-row {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
}