.footer-container {
    height: 70px;
    width: 100%;
    border-top: 1px solid;
    border-color: #e3e3e3;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.footer-item{
    margin: 5px;
    padding: 5px;
    text-decoration: none;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-size: large;
    color: #000;
}