.container-login {
    min-height: 77vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-box {
    width: 300px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    /* Bordas arredondadas */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.erro-msg {
    margin: 5px;
    font-size: 14px;
}

label {
    display: block;
    margin-bottom: 10px;
    /* Aumentei o espaçamento vertical */
}

input {
    width: 98%;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
    /* Largura e cor da borda ajustadas */
    margin-bottom: 10px;
    /* Espaçamento vertical */
}

button {
    margin-top: 20px;
    width: 100%;
    padding: 10px;
    background-color: #085625;
    color: #fff;
    border: none;
    border-radius: 5px;
    /* Bordas arredondadas */
    cursor: pointer;
}