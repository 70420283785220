.navbar-container {
    background-color: #085625;
    height: 10vh;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    align-content: space-around;
}

.navbar-item {
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: 'Helvetica Neue', Arial, sans-serif;
}

.navbar-link {
    text-decoration: none;
    color: #fff;
    font-family: Arial, Helvetica, sans-serif;
}