.evento-container {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.buttons-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.date-button {
    padding: 8px 12px;
    width: 90px;
    height: 40px;
    border: none;
    background-color: #727272;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
    font-weight: bold;
}

.date-button.active {
    background-color: #085625;
}

.date-button:hover {
    background-color: #0d7c36;
}

.item {
    display: flex;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin: 8px;
    background-color: #f5f5f5;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    text-transform: capitalize;
    justify-content: space-between;
}

.ranking {
    width: 80%;
}

.medal-icon1 {
    color: gold;
    margin-right: 8px;
}

.medal-icon2 {
    color: silver;
    margin-right: 8px;
}

.medal-icon3 {
    color: #b87333;
    margin-right: 8px;
}

.medal-iconx {
    color: #b87333;
    margin-right: 8px;
}

h5 {
    text-align: left;
    margin-left: 1%;
    margin-top: 2%;
}

h6 {
    text-align: left;
    margin-top: 2%;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-size: 13px;
    color: #444;
}

/* Estilos para telas de desktop */
@media (min-width: 768px) {
    .ranking {
        width: 70%;
    }
}

/* Estilos para telas de celular */
@media (max-width: 767px) {
    .ranking {
        width: 85%;
    }

    .item {
        display: flex;
        padding: 8px;
        margin: 8px 0;
        border: 1px solid #ccc;
        border-radius: 8px;
        background-color: #f5f5f5;
        flex-wrap: nowrap;
        flex-direction: row;
        text-transform: capitalize;
    }

    .estilo-pts {
        font-size: 12px;
    }
}